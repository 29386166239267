<template>
    <div class="dmDetail">
        <div class="dmdBox">
            <div class="dbBox">
                <!-- 左边区域 -->
                <div class="dbLeft">
                    <div class="dlTop">
                        <div class="dt1">
                            <div class="dt1Left">
                                <div class="d1lp">0.bj.cn</div>
                                <div class="d1lb">一口价（优选）域名</div>
                            </div>
                            <div class="dt1Right">
                                <p class="d1rP">返回域名商城</p>
                                <p class="d1rP">WHOIS信息</p>
                            </div>
                        </div>
                        <div class="dt2">
                            <p class="d2P">域名注册时间：2020-04-24 08:00:00</p>
                            <p class="d2P">出售到期时间：2079-01-01 08:00:00</p>
                        </div>
                        <div class="dt3">
                            <div class="dt3Box">
                                <p class="d3P">威有14983</p>
                                <div class="d3B">立即想要</div>
                                <div class="d3B" @click="goDetail">委托想要</div>
                            </div>
                        </div>
                    </div>
                    <div class="dlBottom">
                        <div class="dlbBox">
                            <div class="dlb1">
                                <p class="d1P">交易方式简介</p>
                            </div>
                            <div class="d1b2">一口价域名可直接安装公式价格立即想要，快速便携，具体流程如下：</div>
                            <div class="d1b3">
                                <ul class="d3Ul">
                                    <li class="d3Li">
                                        <p class="d3n">1</p>
                                        <p class="d3P">点击想要</p>
                                        <i class="iconfont icon-youbianjiantou"></i>
                                    </li>
                                    <li class="d3Li">
                                        <p class="d3n">2</p>
                                        <p class="d3P">提交实名模板</p>
                                        <i class="iconfont icon-youbianjiantou"></i>
                                    </li>
                                    <li class="d3Li">
                                        <p class="d3n">3</p>
                                        <p class="d3P">确认支付</p>
                                        <i class="iconfont icon-youbianjiantou"></i>
                                    </li>
                                    <li class="d3Li">
                                        <p class="d3n">4</p>
                                        <p class="d3P">域名交割</p>
                                        <i class="iconfont icon-youbianjiantou"></i>
                                    </li>
                                </ul>
                            </div>
                            <div class="d1b4">
                                <p class="d4P">重要提示</p>
                                <ul class="d4Ul">
                                    <li class="d4Li" v-for="item in 8" :key="item">
                                        想要一口价优选域名成功后，不能放弃想要或申请退款。
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 右边区域 -->
                <div class="dbRight">
                    <div class="drBox">
                        <div class="drbTop">域名后缀推荐</div>
                        <ul class="drbUl">
                            <li class="drbLi" v-for="item in 16" :key="item">
                                <p class="dbl1">.cn</p>
                                <p class="dbl2">注册量最大的中文后缀</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DmDetail",
    methods: {
        goDetail() {
            this.$router.push("/dmSale");
        },
    },
};
</script>

<style lang="less" scoped>
.dmDetail {
    width: 100%;
    height: 900px;
    margin-top: 87px;
    background-color: #eee;
    overflow: hidden;
    .dmdBox {
        width: 1280px;
        height: 90%;
        margin: 0 auto;
        margin-top: 50px;
        background-color: white;
        box-shadow: 1px 1px 10px 1px gainsboro;
        border-radius: 10px;
        overflow: hidden;
        .dbBox {
            width: 90%;
            height: 90%;
            margin: 0 auto;
            margin-top: 3%;
            background-color: #fff;
            // 左边区域
            .dbLeft {
                width: 70%;
                height: 100%;
                float: left;
                .dlTop {
                    width: 100%;
                    height: 150px;
                    .dt1 {
                        width: 100%;
                        height: 40px;
                        border-bottom: 1px solid gainsboro;
                        .dt1Left {
                            width: 300px;
                            height: 40px;
                            float: left;
                            .d1lp {
                                width: 90px;
                                height: 30px;
                                line-height: 30px;
                                font-size: 30px;
                                float: left;
                            }
                            .d1lb {
                                width: 128px;
                                height: 18px;
                                line-height: 18px;
                                font-size: 12px;
                                float: left;
                                background-color: #7781f1;
                                border-radius: 10px;
                                color: white;
                                text-align: center;
                                margin-left: 25px;
                                margin-top: 12px;
                            }
                        }
                        .dt1Right {
                            width: 180px;
                            height: 40px;
                            float: right;
                            .d1rP {
                                width: 90px;
                                height: 30px;
                                line-height: 30px;
                                text-align: center;
                                float: left;
                                font-size: 12px;
                                color: #7781f1;
                                cursor: pointer;
                            }
                        }
                    }
                    .dt2 {
                        width: 100%;
                        height: 60px;
                        border-bottom: 1px solid gainsboro;
                        .d2P {
                            width: 250px;
                            height: 16px;
                            line-height: 16px;
                            font-size: 14px;
                            float: left;
                            margin-top: 20px;
                        }
                        .d2P:nth-child(2) {
                            float: right;
                        }
                    }
                    .dt3 {
                        width: 100%;
                        height: 50px;
                        .dt3Box {
                            width: 350px;
                            height: 30px;
                            float: right;
                            margin-top: 10px;
                            .d3P {
                                width: 100px;
                                height: 30px;
                                text-align: center;
                                line-height: 30px;
                                float: left;
                                font-size: 20px;
                                color: #7781f1;
                            }
                            .d3B {
                                width: 77px;
                                height: 30px;
                                line-height: 30px;
                                background-color: #7781f1;
                                border-radius: 5px;
                                color: white;
                                float: left;
                                font-size: 12px;
                                text-align: center;
                                margin-left: 25px;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .dlBottom {
                    width: 100%;
                    height: 500px;
                    margin-top: 80px;
                    border-radius: 10px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    overflow: hidden;
                    .dlbBox {
                        width: 95%;
                        height: 90%;
                        margin: 0 auto;
                        margin-top: 3%;
                        .dlb1 {
                            width: 100%;
                            height: 28px;
                            .d1P {
                                float: left;
                                font-size: 18px;
                            }
                        }
                        .dlb1::before {
                            content: "";
                            display: inline-block;
                            background-color: #2468f2;
                            width: 4px;
                            height: 22px;
                            margin-right: 20px;
                            float: left;
                        }
                        .d1b2 {
                            width: 100%;
                            height: 20px;
                            line-height: 20px;
                            font-size: 12px;
                            margin-top: 20px;
                        }
                        .d1b3 {
                            width: 100%;
                            height: 30px;
                            margin-top: 20px;
                            .d3Ul {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                .d3Li {
                                    min-width: 120px;
                                    height: 30px;
                                    font-size: 14px;
                                    margin-left: 10px;
                                    .d3n {
                                        width: 24px;
                                        height: 24px;
                                        line-height: 24px;
                                        text-align: center;
                                        border-radius: 50%;
                                        background-color: black;
                                        color: white;
                                        float: left;
                                    }
                                    .d3P {
                                        min-width: 70px;
                                        height: 16px;
                                        text-align: center;
                                        line-height: 16px;
                                        margin-top: 5px;
                                        margin-left: 5px;
                                        float: left;
                                    }
                                    .iconfont {
                                        margin-top: 5px;
                                        font-weight: 900;
                                        float: left;
                                    }
                                }
                            }
                        }
                        .d1b4 {
                            width: 100%;
                            height: 320px;
                            margin-top: 10px;
                            background-color: #fcf7f1;
                            border-radius: 10px;
                            overflow: hidden;
                            .d4P {
                                width: 90%;
                                height: 14px;
                                line-height: 14px;
                                font-size: 12px;
                                margin: 0 auto;
                                margin-top: 10px;
                            }
                            .d4Ul {
                                width: 90%;
                                height: 250px;
                                list-style: square;
                                margin: 0 auto;
                                margin-top: 10px;
                                display: flex;
                                justify-content: space-around;
                                flex-wrap: wrap;
                                .d4Li {
                                    width: 100%;
                                    height: 20px;
                                    line-height: 20px;
                                    font-size: 12px;
                                }
                                .d4Li:nth-child(-n + 3) {
                                    color: #d14841;
                                }
                            }
                        }
                    }
                }
            }
            // 右边区域
            .dbRight {
                width: 25%;
                height: 100%;
                float: right;
                .drBox {
                    width: 90%;
                    height: 90%;
                    margin: 0 auto;
                    .drbTop {
                        width: 100%;
                        height: 52px;
                        line-height: 52px;
                        font-size: 18px;
                    }
                    .drbUl {
                        width: 100%;
                        height: 575px;
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        .drbLi {
                            width: 100%;
                            height: 36px;
                            line-height: 36px;
                            font-size: 12px;
                            .dbl1 {
                                float: left;
                                cursor: pointer;
                            }
                            .dbl1:hover {
                                color: #7781f1;
                            }
                            .dbl2 {
                                float: right;
                                color: gray;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
